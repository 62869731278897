.col{
    border: 1px solid rgb(213 , 213 , 213);
    color: black;
}

.header {
    background: rgba(180, 23, 73, 1);
    color: white;
}

.content {
    width: 90%;
    padding-top: 10px;
    justify-content: center;
}

.detail {
    ion-row {
        color: black;
        width: 100%;
    }
    
    border-radius: 4px;
    border: 2px solid rgb(180, 23, 73) !important;
    color: rgb(180, 23, 73) !important;   
    margin-bottom: 5px;
    margin-top: 5px;
}

.textDetail{
    margin-bottom: 5px;
    margin-top: 5px;
}