.card {
    width: 90%;
}

.center {
    display: flex;
    justify-content: center;
}

.textLabel {
    color: black;
    font-size: 12px;
    margin-left: 15px;
}

.textArticles{
    font-size: 12px !important;
    color: black;
    margin-left: 15px;
}

.tittle{
    font-size: 14px;
}

.articleMovement{
    ion-segment-button {
        --indicator-color: rgb(180, 23, 73);
        font-size: 12px !important;
        --color-checked: #fff;
    }

    /* Material Design styles */
    ion-segment-button.md {
        --color: #000;
        --color-checked: rgb(180, 23, 73);
        --indicator-height: 4px;
    }

    /* iOS styles */
    ion-segment-button.ios {
        --color: rgb(180, 23, 73);
        --color-checked: #fff;
        --border-radius: 20px;
        color: white;
    }
}

.singleInput{
    position: relative;
    justify-content: center;
    display: flex;
    margin-top: 10px;

    label{
        position: absolute;
        bottom: 5px;
        left: 7%;
        color: rgb(150,150,150);
        cursor: text;
        transition: 0.5s ease-in-out;
    }

    input{
        width: 90%;
        padding: 5px;
        border: 0;
        border: 1px solid rgb(200,200,200);
        outline: 0;
        font-size: 16px;
        color: rgb(80,80,80);
        transition: 0.5s ease-in-out;
        border-radius: 4px;
    }

    input:focus,
    input:valid{
        border: 1px solid rgb(180, 23, 73);
    }
    input:focus ~ label,
    input:valid ~ label{
        transform: translateY(-20px);
        font-size: 12px;
        color: rgb(180, 23, 73);
        background: white;
    }
}







