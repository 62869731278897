
.center {
    display: flex;
    justify-content: center;

}

.items{
    color: black;
    font-weight: bold;
}

.col{
    border: 1px solid rgb(213 , 213 , 213);
}

.description{
    font-size: 14px;
}

.itemArticle{
    background: white;
}

.listArticles{
    background: transparent;
}

.content{
    overflow: auto;
    height: 65vh;
    width: 90%;

    ion-row {
        border: 1px solid rgb(213 , 213 , 213);
        margin-bottom: 5px;
        border-radius: 4px;
    }

    h2 {
        color: black;
    }

    ion-input {
        padding: 10px 16px;
        font-size: 14;
        color: var(--black);
        background-color: #fff;
        border: 1px solid rgb(213 , 213 , 213);
        border-radius: 8px;
        margin-top: 5px;
        width: 100%;
        transition: all 0.3s ease;
    }
} 

.saveButton{
    position: fixed;
    
    ion-fab-button {
        --background: rgb(180, 23, 73);
        --background-activated: rgb(180, 23, 73);
        --background-hover: rgb(180, 23, 73);
        width: 50px;
        height: 50px;
    }
}