ion-modal#example-modal {
    --height: 63%;
    --border-radius: 16px;
    --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal#example-modal::part(backdrop) {
    background: rgba(209, 213, 219);
    opacity: 1;
}

ion-modal#example-modal ion-toolbar {
    --background: rgba(180, 23, 73, 1);
    --color: white;
}