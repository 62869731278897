.center {
    display: flex;
    justify-content: center;
}

.card {
    width: 90%;
}

.search {
    border: 1px solid #9c9c9c !important;
    background-color: rgb(235, 235, 235)  !important;
    border-radius: 10px;

    ion-icon {
        font-size: 22px;
    }
}

.iconSearch{
    display: flex;
    align-items: center;
    justify-content: center;
}
