.tittle {
    font-size: 14px;
}

.centerContent{
    display: flex;
    justify-content: center;
}

.contentCalculator{
    width: 80%;
}

.buttonCalculator{
    padding: 1rem;
    border-radius: 0.5rem;
    width: 80%;
    font-weight: bold;
}

.letterOperation{
    font-size: 20px;
    width: 100%;
    display: block;
    text-align: right;
    padding-right: 10px;
    padding-top: 5px;
}

.letterResult{
    font-size: 24px;
    width: 100%;
    display: block;
    text-align: right;
    color: black;
    padding-right: 10px;
}

.buttonCalculatorOp{
    padding: 1rem;
    border-radius: 0.5rem;
    width: 80%;
    background: rgba(180, 23, 73, 1);
    color: white;
    font-weight: bold;
}

.buttonCalculatorGreater{
    padding: 1rem;
    border-radius: 0.5rem;
    width: 90%;
    font-weight: bold;
}

.calculatorScreen{
    border: 1px solid #9c9c9c !important;
    background-color: rgb(244, 244, 244)  !important;
    border-radius: 10px;
    height: 100%;
    width: 95%;
    min-height: 65px;
}

.tittleCalculator{
    height: 45px;
}